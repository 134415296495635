/* main logo in sidebar */
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
}


/* footer */
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-footer-content{
margin-left: auto;
    
}

/* add user button */
.add-user-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* profile upload in Add user page */
#profile-upload{
    opacity: 1;
    position: inherit;
}

/* feature  */
.service-img{
    width: 50px;
    height: 48px;
 
}

/* pagination */
.page-link{
    color: rgba(34, 42, 66, 0.7) !important;
}

.pagination{
    justify-content: flex-end;
}

/* table in all pages*/
.table-responsive {
    overflow: hidden !important;
}
.add-icon-group,.remove-icon-group {
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important; 
}

.bootstrap-table,.table > thead > tr > th{
    border: 1 !important;
}
/* add and minus icon group in add permission page */
.add-btn,.remove-btn, .add-btn:hover, .remove-btn:hover, .add-btn:focus, .remove-btn:focus{
    background: white;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    outline: none;
}

.add-icon,.minus-icon{
    color: darkgray;
}


/* pagination select item dropdown button */
 #pageDropDown{
    background-color: #1d8cf8 !important;
    background-image: none;
    width: 45px;
    height:35px;
   }

   #pageDropDown:focus, #pageDropDown:hover, #pageDropDown:active {
    background-color: #1d8cf8 !important;
    background-image: none !important;
    border-color: #1d8cf8 !important ;
   }

   .btn.dropdown-toggle:after,  #pageDropDown:after,.navbar .navbar-nav > a.btn.dropdown-toggle:after {
    margin-left: 6px !important;
   }



/* tag */
.tag {
    background-color: #1d8cf8;
    color: white;
    border-radius: 15px;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
   
  }

 
  /* service-feature-listing */
  .service-feature-listing{
      display: flex;
  }

  /* feature listing */
  .feature-listing{
      display: flex !important;
     
  }

  /* side bar list */
  .sidebar .nav li > a{
      font-size: 0.85rem;
  }

  /* edit permission button */
  .edit-permission-btn{
      background-color: none;
      background: none;
      background-image: none;
      cursor: pointer;
  }

/* edit icon */
  .edit-icon{
      color:#1d8cf8;
  }

/* profile name in nav bar */
.profile-name{
    width: auto;
    align-items: center;
    display: flex;
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* unauthorized message */
.unauthorized-msg-body{
    width: 100%;
    height: 300px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.search-label{
    width: 100%;    
}